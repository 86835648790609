<template>
  <div>
    <!--begin::Resources-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Resources List</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center" v-if="user_permissions.includes('chapter_add_resources') || user_permissions.includes('se_add_resources')">
            <div class="my-2">
              <a
                @click="redirectToCreateResource()"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,
                        2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,
                        7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,
                        22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,
                        2 5.85714286,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      ></path>
                      <path
                        d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,
                        12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,
                        10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,
                        14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,
                        16.5522847 11,16 L11,14 Z"
                        fill="#000000"
                      ></path>
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
                Add Resource
              </a>
              <!-- <button
                type="button"
                class="v-btn v-btn--contained theme--dark v-size--default primary"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToCreateResource()"
                >
                  Add Resource
                </span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_resource_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
                <div class="col-md-4 my-2 my-md-0 pr-0">
                  <div class="input-icon">
                    <v-text-field
                      v-model="search"
                      single-line
                      placeholder="Search"
                      class="form-control"
                      hide-details
                    ></v-text-field>
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </v-card-title>
              <v-data-table :headers="headers" :items="resource_list">
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.name }}</td>
                    <td>{{ row.item.resource_type }}</td>
                    <td>
                      <v-tooltip top v-if="user_permissions.includes('chapter_add_resources') || user_permissions.includes('se_add_resources')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="resourceEdit(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Resource</span>
                      </v-tooltip>
                      <v-tooltip top v-if="user_permissions.includes('chapter_add_resources') || user_permissions.includes('se_add_resources')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="resourceDelete(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Resource</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="resourceView(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>View Resource</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Resource-->
    <!-- View Resource Dialogue -->
    <div class="text-center">
      <v-dialog v-model="resource_dialog" width="70%">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Resource View
          </v-card-title>

          <v-card-text class="text-h6 pa-5">
            <b>Resource Name: </b> {{ resource.name }} <br />
            <b v-if="resource.resource_path">Resource Download: </b> <a @click="downloadResource(resource.id)" v-if="resource.resource_path">Click here</a> <br v-if="resource.resource_path"/>
            <b>Resource URL: </b> <a @click="openResourceURL(resource.url)">{{ resource.url }}</a> <br />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="resource_dialog = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- View Resource Dialogue -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DELETE_RESOURCE } from "@/core/services/store/resource.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "resourcesList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      user_permissions: [],
      resource_list: [],
      search: "",
      resource: {},
      resource_dialog: false,
      headers: [
        { text: "Name", align: "left", value: "name", width: "33%" },
        { text: "Resource Type", value: "resource_type", width: "33%" },
        { text: "Action", sortable: false, value: "action", align: "left" }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig","getUserPermissions"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Resource" }]);
    this.getResourcesList();
    this.$store.dispatch(GET_COMPANY);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  watch: {
    search: function(newVal) {
      this.getResourcesList(newVal);
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  methods: {
    downloadResource(res_id) {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/downloadResource/" +
        res_id;
      window.open(url);
    },
    resourceView(res_id) {
      let context = this;
      axios({
        method: "get",
        url: "resource/" + res_id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.resource_dialog = true;
          context.resource.id = result.data.resource.id;
          context.resource.name = result.data.resource.name;
          context.resource.url = result.data.resource.url;
          context.resource.resource_path = result.data.resource.resource_path;
        },
        function(error) {
          Swal.fire(error, "", "info");
          // console.log(error);
        }
      );
    },
    resourceDelete(res_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Resource file!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_RESOURCE, res_id).then(() => {
            this.getResourcesList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Resource File is safe.", "", "info");
        }
      });
    },
    redirectToCreateResource() {
      this.$router.push({ name: "resourceCreate" });
    },
    getResourcesList(search_str = "") {
      // this.$store.dispatch(GET_DOCUMENT_LIST).then(() => {
      //   this.document_list = this.stateDocumentsList;
      // });
      let context = this;
      let api_url = "resources";
      if (search_str) {
        api_url = "resources?search_str=" + search_str;
      }
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.resource_list = result.data.resources;
        },
        function(error) {
          Swal.fire(error, "", "info");
          // console.log(error);
        }
      );
    },
    resourceEdit(res_id) {
      this.$router.push({ name: "resource.edit", params: { id: res_id } });
    },
    openResourceURL(res_url) {
      if(!(res_url.startsWith("http"))){
          res_url = 'http://'+res_url;
      }
      window.open(res_url, "_blank");  
    }
  }
};
</script>
